<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('Dictionary Manage')}}</div>
            </div>
            <template>
                <el-col :span="4">
                    <el-menu :default-active="categoryId"
                             class="el-menu-vertical-demo"
                             @open="handleOpen">
                        <template v-for="(item,i) in arrList">
                            <el-menu-item :index="item.id" @click="list(item)" v-bind:key="i">
                                <span slot="title">{{ item.name }}</span>
                            </el-menu-item>
                        </template>
                    </el-menu>
                </el-col>
                <el-col :span="20">
                    <avue-crud :option="tableOption"
                               :data="tableData"
                               ref="crud"
                               @refresh-change="refreshChange"
                               @row-save="handleSave"
                               @row-update="handleUpdate"
                               @row-del="handleDel">
                        <template slot="menuLeft" style="width: calc(100% - 80px);">
                            <el-row :gutter="20">
                                <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                </el-col>
                                <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px; text-align: right; align-self: flex-end;">
                                    <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="$refs.crud.rowAdd()"> {{$t('New')}}</el-button>
                                    <el-button :title="$t('Refresh')" icon="iconfont shuaxin" size="mini" plain @click="refreshChange">  {{$t('Refresh')}}</el-button>
                                </el-col>
                            </el-row>
                        </template>


                    </avue-crud>
                </el-col>
            </template>
        </basic-container>
    </div>
</template>
<script>
import {list, treeList, add, remove, edit} from "@/api/dic"
import importBtn from "@/components/importExport/importBtn";

export default {
  components: {
    importBtn
  },
  data() {
    return {
      item: "",
      arrList: [],
      tableData: [],
        tableOption: {
            refreshBtn: false,
          delBtn: true,
          addBtn: false,
        menuWidth: 200,
        columnBtn: false,
        dialogFullscreen: true,
        addBtnText: this.$t('ADD'),
        delBtnText: this.$t('DELETE'),
        editBtnText: this.$t('Edit'),
        emptyText: this.$t('No Data'),
        menuTitle: this.$t('OPERATION'),
        column: [{
          label: this.$t('NAME'),
          prop: "name",
          placeholder: this.$t('PLEASE ENTER A NAME'),
          rules: [{
            required: true,
            message: this.$t('PLEASE ENTER A NAME'),
            trigger: "blur"
          }]
        }, {
          label: this.$t('CODE'),
          prop: "code",
          placeholder: this.$t('PLEASE ENTER THE CODE'),
          rules: [{
            required: true,
            message: this.$t('PLEASE ENTER THE CODE'),
            trigger: "blur",
          }]
        }, {
          label: this.$t('SORT'),
          sortable: true,
          prop: "sort",
          type: "number",
          rules: [{
            required: true,
            message: this.$t('PLEASE ENTER SORTING'),
            trigger: "blur"
          }],
          value: 0
        }, {
          label: this.$t('HIGHER LEVEL DICTIONARY'),
          display: false,
          prop: "parentCodeName"

        }, {
          label: this.$t('HIGHER LEVEL DICTIONARY'),
          hide: true,
          placeholder: this.$t('PLEASE SELECT HIGHER LEVEL DICTIONARY'),
          dicData: [],
          type: "select",
          props: {
            label: "name",
            value: "code"
          },
          prop: "parentCode"
        }, {
          label: this.$t('REMARK'),
          hide: true,
          display: false,
          prop: "remarks"
        }]
      },
      categoryId: '',
      routeCategoryId: ''
    }
  },
  created() {
    this.init();
  },
  methods: {
    refreshData() {
      treeList().then(res => {
        this.arrList = res.data.data;
        this.item = res.data.data[0];
        this.categoryId = res.data.data[0].id
        this.list(this.item);
      })
    },
    handleUpdate(row, index, done) {
      edit(this.item.id, row).then(res => {
        this.list(this.item);
        res.data.code == "0000" ? this.$message({
          showClose: true,
          message: this.$t('EDIT SUCCEEDED'),
          type: "success"
        }) : "";
      })
      done();
    },
    handleDel(row) {
      this.$confirm(this.$t('IsDel') + row.name, this.$t('Tips'), {
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          console.log(res);
          this.list(this.item);
          this.$message({
            showClose: true,
            message: this.$t('DeleteSuccess'),
            type: "success"
          });
        });
      })
          .catch(() => {
          });
    },
    handleOpen() {
      alert("loading");
    },
    handleSave(parmas, done) {
      add(this.categoryId, parmas, done).then((res) => {
        console.log(this.categoryId, parmas);
        if (res.data.code == "0000") {
          this.$message({
            type: "success",
            message: this.$t('AddSuccess')
          });
        }
        this.list(this.item);
        done();
      })
    },
    refreshChange() {
      list()
    },
    list(item) {
      this.categoryId = item.id
      this.item = item;
      list(item.id).then(res => {
        this.tableData = res.data.data;
        this.tableOption.column[4].dicData = res.data.data;
        res.data.data.forEach(v => {
          !v.parentCodeName ? v.parentCodeName = "-" : "";
        });
      });
    },
    envText: function () {
      return this.env
    },
    init() {
      treeList().then(res => {
        this.routeCategoryId = this.$route.query.DicId;
        this.arrList = res.data.data;
        if (this.routeCategoryId != undefined && this.routeCategoryId != '') {
          var objrow1 = {};
          objrow1 = this.arrList.find((i, index) => {//这里的List就是上面遍历的数据源
            return i.id === this.routeCategoryId;//筛选出匹配数据
          });
          if (objrow1 != null) {
            this.item = objrow1;
          }
        } else {
          this.item = res.data.data[0];
        }
        setTimeout(() => {
          this.categoryId = this.item.id
        }, 50)
        this.list(this.item);
      })
    }
  },
  watch: {
    $route() {
      this.init()
    }
  },
}
</script>
