var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("Dictionary Manage")))]
            )
          ]),
          [
            _c(
              "el-col",
              { attrs: { span: 4 } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-vertical-demo",
                    attrs: { "default-active": _vm.categoryId },
                    on: { open: _vm.handleOpen }
                  },
                  [
                    _vm._l(_vm.arrList, function(item, i) {
                      return [
                        _c(
                          "el-menu-item",
                          {
                            key: i,
                            attrs: { index: item.id },
                            on: {
                              click: function($event) {
                                return _vm.list(item)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 20 } },
              [
                _c(
                  "avue-crud",
                  {
                    ref: "crud",
                    attrs: { option: _vm.tableOption, data: _vm.tableData },
                    on: {
                      "refresh-change": _vm.refreshChange,
                      "row-save": _vm.handleSave,
                      "row-update": _vm.handleUpdate,
                      "row-del": _vm.handleDel
                    }
                  },
                  [
                    _c(
                      "template",
                      {
                        staticStyle: { width: "calc(100% - 80px)" },
                        slot: "menuLeft"
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 20 } },
                          [
                            _c("el-col", {
                              staticClass: "fromOutDiv",
                              attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                            }),
                            _c(
                              "el-col",
                              {
                                staticStyle: {
                                  "padding-top": "10px",
                                  "text-align": "right",
                                  "align-self": "flex-end"
                                },
                                attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "iconfont tianjia",
                                      size: "mini",
                                      title: _vm.$t("New"),
                                      plain: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.crud.rowAdd()
                                      }
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("New")))]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      title: _vm.$t("Refresh"),
                                      icon: "iconfont shuaxin",
                                      size: "mini",
                                      plain: ""
                                    },
                                    on: { click: _vm.refreshChange }
                                  },
                                  [_vm._v("  " + _vm._s(_vm.$t("Refresh")))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }